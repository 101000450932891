import React, { useState } from 'react';
import Container from 'components/ui/Container';

import * as Styled from './styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { addToWaitList } from '../../state/user/user.service';
import ChatImg from './../../assets/images/thank-you-waitlist.png'
import { useI18next } from "gatsby-plugin-react-i18next";
import { navigate as nativeNavigate } from "gatsby-link";
import { useStaticQuery, graphql } from "gatsby";
import { tap } from "rxjs/operators";
import Danger from "../ui/Alert/Danger";
import PropTypes from "prop-types";


const Unserviced = ({ locale, pdata }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        go_back
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        go_back
      }
      strapiUnservicedPage_en: strapiUnservicedPage(locale: {eq: "en"}) {
        heading
        sub_heading
        email_field_label
        submit_email_button_label
        thank_you_for_signing_up
        back_to_shop_link
        after_signup_text
      }
      strapiUnservicedPage_zh: strapiUnservicedPage(locale: {eq: "zh"}) {
        heading
        sub_heading
        email_field_label
        submit_email_button_label
        thank_you_for_signing_up
        back_to_shop_link
        after_signup_text
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: {eq: "en"}) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: {eq: "zh"}) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
    }
  `);
  const translations = gql[`strapiUnservicedPage_${locale}`];
  const navTranslations = gql[`strapiNavigationMenu_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [errors, setErrors] = useState([]);
  const { navigate } = useI18next();
  const [successSubmit, setSuccessSubmit] = useState(false);

  return (
    <Container section>
      <Styled.Wrapper>
       {!successSubmit && (
        <>
        <Styled.Text bold>{translations.heading} </Styled.Text>
        <Styled.Text>
          {translations.sub_heading}
        </Styled.Text>
        <br />
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = errTranslations.email_address_required;
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = errTranslations.invalid_email_address
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setErrors([])
            await addToWaitList(values.email, pdata)
              .pipe(
                tap(() => {
                  setSuccessSubmit(true);
                })
              )
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500: setErrors([errTranslations.server_error_500]); break;
                  case 502: setErrors([errTranslations.server_error_502]); break;
                  case 400: setErrors([errTranslations.request_error_422]); break;
                  case 401: setErrors([errTranslations.invalid_login_credentials]); break;
                  case 422: setErrors([errTranslations.request_error_422]); break;
                  default: setErrors([errTranslations.unknown_error]); break;
                }
              });

          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Styled.Text small>{translations.email_field_label}</Styled.Text>
              <Field type="email" name="email">
                {({ field, meta: { touched, error } }) => (
                  <input
                    className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="div"
                className="my-1 pb-2 text-xs text-red-600"
              />

              <Styled.Button type="submit" disabled={isSubmitting}>
                {translations.submit_email_button_label}
              </Styled.Button>
            </Form>
          )}
        </Formik>
        {errors.map((err) => (
          <Danger message={err} />
        ))}
        </> )}
        {successSubmit && (
        <Styled.ThankYou>
          <img src={ChatImg} alt='Thank you waitlist'/>
          <Styled.Text bold>{translations.thank_you_for_signing_up}</Styled.Text>
          <Styled.Text>{translations.after_signup_text}</Styled.Text>
          <Styled.Button onClick={() => navigate('/shop/')}>{translations.back_to_shop_link}</Styled.Button>
        </Styled.ThankYou>
        )}
      </Styled.Wrapper>
      <Styled.Mobile>
        <button
          className="my-4 text-blue-600 text-xs"
          onClick={() => nativeNavigate(-1)}
        >
          <span className='flex mx-auto'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
            <label>{navTranslations.go_back}</label>
            <br/><br/>
          </span>
        </button>
      </Styled.Mobile>

    </Container>
  );
};

Unserviced.propTypes = {
  locale: PropTypes.string,
};

Unserviced.defaultProps = {
  locale: 'en',
};

export default Unserviced;
