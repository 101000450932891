import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`flex-shrink bg-white w-full sm:w-6/12 md:w-c1 h-auto mx-auto py-6 px-4 mt-16 sm:mt-0`};
  a{
    ${tw`text-blue-500`};
  }
  input{
    letter-spacing: 0.3px;
    ${tw`text-brown-darker text-xssemi`};
  }
`;

export const Text = styled.p`
  letter-spacing: 0.04em;
  ${tw`text-sm text-brown-darker m-0 mb-1`};
  ${({ bold }) => (bold ? tw`font-bold text-base text-brown-semidark` : tw``)};
  ${({ center }) => (center ? tw`my-4 mx-auto text-center` : tw``)};
  ${({ left }) => (left ? tw`flex-auto text-xs text-left` : tw``)};
  ${({ right }) => (right ? tw`flex-auto text-xs text-right` : tw``)};
  ${({ small }) => (small ? tw`text-xs  font-semibold` : tw``)};
  ${({ centermb }) => (centermb ? tw`mb-2 mx-auto text-center` : tw``)};
  ${({ link }) => (link ? tw`text-blue-500` : tw``)};
`;

export const Button = styled.button`
  letter-spacing: 0.05em;
  ${tw`w-full mt-6 mx-auto bg-black text-base font-medium gap-2 py-3 text-white`};
`;

export const ThankYou = styled.div`
  ${tw`w-full text-center`};
  p{
    ${tw`my-6`};
  }
  img{
    ${tw`mx-auto my-4`};
  }
  button{
    ${tw`mt-0`};
  }
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden px-4`};
`;
