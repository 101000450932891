import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Unserviced from '../components/Unserviced';
import { I18nextContext } from "gatsby-plugin-react-i18next";
import { getQueryVariable } from "../state/utils";

const UnservicedPage: React.FC = (props: any) => {
  const { language } = React.useContext(I18nextContext);
  const pdata = getQueryVariable('pdata', props.location)
  return (
    <Layout locale={language}>
        <SEO title="Unserviced" />
        <Unserviced locale={language} pdata={pdata}/>
    </Layout>
  );
};

export default UnservicedPage;
